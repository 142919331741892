<template>
  <div class="eventregister-sec">
    <div class="container event-register-container">
      <div class="row event-register-row">
        <div class="col-12 event-register-form">
          <div class="register-form-wrap">
            <div class="head">
              <h3>{{ $t('Event.FORM.LABEL_EVENT_REGISTER') }}</h3>
              <p>{{ $t('Event.FORM.TEXT_EVENT_REGISTER_DESCRIPTION') }} {{ this.events.title }}.</p>
            </div>
            <div class="register-form">
              <event-register-form
                :event-id="eventId"
                :event-data="this.events"
                :maximum-limit="settingData.maximum_limit_message"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-link tag="a" to="/events" class="btn btn-theme back-route-path-btn" v-if="eventBackBtnEnable"></router-link>
  </div>
</template>

<script>
  import EventRegisterForm from '../components/event/EventRegisterForm'
  import {mapGetters} from "vuex";

  export default {
    name: 'EventRegister',

    components: {
      EventRegisterForm
    },

    data () {
      return {
        eventId: '',
        events: {}
      }
    },
    computed: {
      ...mapGetters({ settingsId: 'getSettingId', settingData : 'getSettingData', eventBackBtnEnable: 'getEventBackBtnFlag' }),

      eventData() {
        return this.$store.getters.getEventById(Number(this.$route.params.id));
      }
    },
    mounted () {
      this.loadEvents()

      this.eventId = this.$route.params.id.toString()
    },
    methods: {
      loadEvents() {
        this.$root.$load(true)

        this.$http.post('get-event-detail', {setting_id: this.settingsId, event_id: this.$route.params.id.toString()})
          .then((response) => {
            if (response.status === 200) {
              const items = response.data.dataset
              this.events = items
            }
          })
          .catch((e) => {
            console.log(e)
          })
          .finally(() => { this.$root.$load(false) })
      }
    }
  }
</script>
