<template>
  <form
    class="row row-registration"
    @submit.prevent="proceedRegistration"
  >

    <div class="form-group col-lg-12 col-xl-12 col-md-12 col-sm-12 event-register event-register-name">
      <label for="user_name">{{ $t('Event.FORM.LABEL_NAME') }}</label>
      <input
        v-model="formData.user_name"
        v-validate="'required'"
        type="text"
        class="form-control"
        id="user_name"
        :name="$t('Event.FORM.LABEL_USER_NAME')"
        :placeholder="$t('Event.FORM.LABEL_USER_NAME')"
        :class="{ 'is-invalid': errors.has($t('Event.FORM.LABEL_USER_NAME'))}"
      />
      <div
        v-if="errors.has($t('Event.FORM.LABEL_USER_NAME'))"
        class="invalid-feedback"
      >
        <p>{{ errors.first($t('Event.FORM.LABEL_USER_NAME')) }}</p>
      </div>
    </div>
    <div class="form-group col-lg-12 col-xl-12 col-md-12 col-sm-12 event-register event-register-location">
      <label for="location">{{ $t('Event.FORM.LABEL_LOCATION') }}</label>
      <input
        v-model="formData.location"
        v-validate="'required'"
        type="text"
        class="form-control"
        id="location"
        :name="$t('Event.FORM.LABEL_LOCATION')"
        :placeholder="$t('Event.FORM.LABEL_LOCATION')"
        :class="{ 'is-invalid': errors.has($t('Event.FORM.LABEL_LOCATION'))}"
      />
      <div
        v-if="errors.has($t('Event.FORM.LABEL_LOCATION'))"
        class="invalid-feedback"
      >
        <p>{{ errors.first($t('Event.FORM.LABEL_LOCATION')) }}</p>
      </div>
    </div>
    <div class="form-group col-lg-12 col-xl-12 col-md-12 col-sm-12 event-register event-register-age">
      <label for="age">{{ $t('Event.FORM.LABEL_AGE') }}</label>
      <input
        v-model="formData.age"
        v-validate="'required'"
        type="text"
        class="form-control"
        id="age"
        :name="$t('Event.FORM.LABEL_AGE')"
        :placeholder="$t('Event.FORM.LABEL_AGE')"
        :class="{ 'is-invalid': errors.has($t('Event.FORM.LABEL_AGE'))}"
      />
      <div
        v-if="errors.has($t('Event.FORM.LABEL_AGE'))"
        class="invalid-feedback"
      >
        <p>{{ errors.first($t('Event.FORM.LABEL_AGE')) }}</p>
      </div>
    </div>
    <div class="form-group col-lg-12 col-xl-12 col-md-12 col-sm-12 event-register event-register-remark">
      <label for="remark">{{ $t('Event.FORM.LABEL_REMARK') }}</label>
      <textarea
        v-model="formData.remark"
        type="text"
        class="form-control"
        id="remark"
        :name="$t('Event.FORM.LABEL_REMARK')"
        :placeholder="$t('Event.FORM.LABEL_REMARK')"
        :class="{ 'is-invalid': errors.has($t('Event.FORM.LABEL_REMARK'))}"
      ></textarea>
      <div
        v-if="errors.has($t('Event.FORM.LABEL_REMARK'))"
        class="invalid-feedback"
      >
        <p>{{ errors.first($t('Event.FORM.LABEL_REMARK')) }}</p>
      </div>
    </div>

    <div class="form-group col-lg-12 col-xl-12 col-md-12 col-sm-12 extra-person" v-for="(person, index) in additional_persons" :key="index">
      <div class="row">
        <div class="form-group col-lg-12 col-xl-12 col-md-12 col-sm-12 extra-person-remove">
          <h3 style="float: left;">{{ $t('Event.FORM.LABEL_EXTRA_PERSON') }} {{index + 1}}</h3> <button class="btn pull-right button-element remove-label-button" type="button" @click="remove(index)" v-show="index || ( !index && additional_persons.length > 0)" style="float: right;"> <i class="fa fa-minus-circle"></i> {{ $t('Event.FORM.LABEL_REMOVE') }}</button>
        </div>

        <div class="form-group col-lg-12 col-xl-12 col-md-12 col-sm-12 extra-person-register extra-person-register-name">
          <label>{{ $t('Event.FORM.LABEL_USER_NAME') }}</label>
          <input
            v-model="person.name"
            type="text"
            class="form-control"
            :placeholder="$t('Event.FORM.LABEL_USER_NAME')"
          />
        </div>

        <div class="form-group col-lg-12 col-xl-12 col-md-12 col-sm-12 extra-person-register extra-person-register-age">
          <label for="remark">{{ $t('Event.FORM.LABEL_AGE') }}</label>
          <input
            v-model="person.age"
            type="text"
            class="form-control"
            :placeholder="$t('Event.FORM.LABEL_AGE')"
          />
      </div>
      </div>
    </div>

    <div id="addSection" v-if="addSection" class="form-group col-lg-12 col-xl-12 col-md-12 col-sm-12 text-center section-add">
      <button type="button" id="add-person" @click="addPerson" class="btn button-element add-person-btn"><i class="fa fa-plus"></i> {{ $t("Event.FORM.LABEL_ADD_EXTRA_PERSON") }}</button>
    </div>
    <div id="limitMessageSection" v-if="limitMessageSection" class="form-group col-lg-12 col-xl-12 col-md-12 col-sm-12 text-center limit-message-section" >
      {{ this.maximumLimit }}
    </div>

    <div class="form-group col-lg-12 col-xl-12 col-md-12 col-sm-12 event-submit-btn">
      <div class="text-center">
        <button
          type="submit"
          class="btn btn-primary button-element label-submit-btn"
        >
          {{ $t('Event.FORM.LABEL_SUBMIT') }}
        </button>
      </div>
    </div>
  </form>
</template>

<style>
  #add-person svg
  {
    border: 1px solid;
    border-radius: 50%;
    padding: 2px;
  }
</style>

<script>
  export default {
    name: 'EventRegisterForm',

    props: {
      eventId: {
        type: String,
        required: true
      },
      eventData: {
        type: Object,
        required: true
      },
      registerButton:
      {
        type: String,
        default: ''
      },
      maximumLimit: {
        type: String,
        default: ''
      }
    },

    $_veeValidate: {
      validator: 'new'
    },

    data () {
      return {
        formData: {
          id: this.eventId,
          user_name: '',
          location: '',
          age: '',
          remark: '',
          additional_persons: []
        },
        additional_persons: [],
        addSection: true,
        limitMessageSection: false
      }
    },

    methods: {
      proceedRegistration () {
        this.$validator.validate().then(isValid => {
          if (isValid) {
            this.formData.id = this.eventId
            this.formData.additional_persons = this.additional_persons
            this.$root.$load(true)

            this.$http.post('event-registration', this.formData)
              .then((response) => {
                if (response.status === 200) {
                  this.$router.push({ name: 'SubscriptionSuccess' })
                }
              })
              .catch((e) => { console.log(e) })
              .finally(() => { this.$root.$load(false) })
          }
        })
      },

      addPerson()
      {
        if(this.eventData.capacity >= this.eventData.registered_events + this.additional_persons.length + 2)
        {
          this.additional_persons.push(
            {
              name: '',
              age: ''
            }
          );
        }
        else
        {
          this.addSection = !this.addSection;
          this.limitMessageSection = !this.limitMessageSection;
        }
      },
      remove (index) {
        this.addSection = true;
        this.limitMessageSection = false;
        this.additional_persons.splice(index, 1)
      },

    }
  }
</script>
